import React from 'react';
import Image from 'next/image';

import AppBarCode from '@assets/images/AppBarCode.png';
import AppStore from '@assets/images/svg/AppStore.svg';
import BluePhone from '@assets/images/svg/phone-textme.svg';
import useIsMobile from '@hooks/use-is-mobile';
import * as colors from '@styles/colors';
import { small } from '@styles/media-queries';

import {
  AppDownloadLinkBtn,
  AppStoreImageWrap,
  BlueBar,
  GetLinkTitleWrap,
} from './home.styled';

interface AppLinkFormProps {
  whiteBackground?: boolean;
}

const AppLinkForm: React.FunctionComponent<AppLinkFormProps> = ({
  whiteBackground = false,
}) => {
  const isMobile = useIsMobile();

  const AppDownloadLink =
    'https://apps.apple.com/us/app/okay-humans-talk-therapy/id1558410258';

  return (
    <BlueBar whiteBackground={whiteBackground}>
      <BluePhone css={{ [small]: { margin: 'auto' } }} />

      <GetLinkTitleWrap
        css={{ color: whiteBackground ? colors.secondary : colors.primary }}
      >
        scan this code to download the app:
      </GetLinkTitleWrap>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        {isMobile ? (
          <a target="_blank" href={AppDownloadLink} rel="noopener noreferrer">
            <AppDownloadLinkBtn>DOWNLOAD THE APP</AppDownloadLinkBtn>
          </a>
        ) : (
          <Image
            src={AppBarCode}
            alt="AppBarCode"
            width={93}
            height={93}
            objectFit="contain"
          />
        )}
        {isMobile ? (
          <AppStoreImageWrap>
            <AppStore />
          </AppStoreImageWrap>
        ) : (
          <a target="_blank" href={AppDownloadLink} rel="noopener noreferrer">
            <AppStoreImageWrap>
              <AppStore />
            </AppStoreImageWrap>
          </a>
        )}
      </div>
    </BlueBar>
  );
};

export default AppLinkForm;
